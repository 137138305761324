<template>
  <div class="marketing-view">
    <Container>
      <Separator size="medium" />
      <Margins>
        <h3>LÅT ETT PROFFS GÖRA JOBBET</h3>
        <b-row>
          <b-col sm="6" xl="8">
            <Margins>
              <p>
                Tänk om du hade en helt egen trädgårdsmästare, som alltid såg
                till att din gräsmatta var perfekt klippt. Upptäck fördelarna
                med STIHL All Inclusive, vår service som gör det både enkelt och
                bekvämt att äga en iMOW® robotgräsklippare.
              </p>
              <p>
                Allt är inkluderat i en fast månadskostnad – installation,
                service och vinterförvaring. Välj bland flera modeller och hitta
                den perfekta matchen för dig och din gräsmatta. Därefter räknas
                den fasta månadskostnaden ut och betalningen delas upp på 60
                månader. Det tillkommer inga räntekostnader, startavgifter eller
                restvärde. Det pris du betalar under finansieringsperioden är
                det pris du skulle betalt för robotgräsklippare, installation,
                vinterservice och vinterförvaring.
              </p>
              <p>Finansieringen görs i samarbete med Resurs Bank.</p>
            </Margins>
          </b-col>
          <b-col cols="6" sm="3" xl="3">
            <img
              :data-src="require('@/assets/images/sai_lp_s1_imows.png')"
              alt="Stihl All Inclusive price"
              class="lazyload margins__mobile-sm"
            />
          </b-col>
        </b-row>

        <Separator hasLine size="medium" />

        <h3>INKLUSIVE ALLT</h3>

        <b-list-group>
          <b-list-group-item class="d-flex flex-row align-items-center mt-3">
            <StihlInstallationSVG class="icon icon--medium fill-white mr-2" />
            INSTALLATIONSHJÄLP*
          </b-list-group-item>
          <b-list-group-item class="d-flex flex-row align-items-center mt-3">
            <StihlServiceSVG
              class="icon icon--medium fill-white mr-2"
            />UPPFÖLJNINGSBESÖK MED DIN ÅTERFÖRSÄLJARE
          </b-list-group-item>
          <b-list-group-item class="d-flex flex-row align-items-center mt-3"
            ><StihlWinterStorageSVG class="icon icon--medium mr-2" />SERVICE OCH
            VINTERFÖRVARING I 4 ÅR
          </b-list-group-item>
        </b-list-group>

        <div class="margins__triple text-left">
          <Button
            @click="$router.push({ name: ROUTES.SELECT_BUNDLE.name })"
            border="none"
            variant="inverse-primary"
          >
            {{ $t('HEADER.HOME') }}
          </Button>
        </div>

        <p class="margins__triple">
          <small class="text-extra-small">
            *Fri standardinstallation inom en radie på 50 km från
            återförsäljarens butik. För sträckor över 50 km och
            installationsplatser utan landförbindelse tillkommer extra
            körkostnad. En standardinstallation innefattar upp till 4 öar
            (rabatter eller annat som roboten ska köra runt och som måste
            inhägnas med slinga.) För önskemål utöver detta, exempelvis
            trädgårdar med många rabatter, gångar eller buskar, avtalas
            eventuell tilläggsavgift direkt med din återförsäljare.
          </small>
        </p>

        <Separator size="medium" hasLine />

        <b-row>
          <b-col sm="6" xl="8">
            <Margins>
              <h3>BEHÅLL ELLER UPPGRADERA</h3>
              <p>
                Ditt STIHL All Inclusive avtal omfattar en årlig service och
                vinterförvaring i 4 år från avtalsstart. Betalningen delas upp
                på totalt 60 månader. Efter 4 år kan du antingen välja att
                förnya ditt avtal och få en ny iMOW®, eller behåller maskinen du
                har.
              </p>
              <p>
                Om du väljer att förnya ditt avtal fortsätter finansieringen med
                samma villkor som tidigare. Restvärde från tidigare avtal
                avräknas mot din nya robotklippares värde. Eventuell
                mellanskillnad läggs på det nya finansieringsavtalet.
              </p>
              <p>
                Den gamla modellen kommer att köpas tillbaka till det restvärde
                som sattes när avtalet ingicks, såvida maskinen inte misskötts
                så att det väsentligen påverkar robotklipparens värde negativt.
                Normalt slitage betraktas inte som misskötsel och den årliga
                vinterservicen bidrar till att förebygga uppkomsten av skador.
              </p>
              <p>
                När din gamla maskin är returnerad och avräknad så kommer den
                att fortsätta klippa och återanvändas på gräsmattor utanför
                Sveriges gränser.
              </p>
              <p>
                Om du väljer att inte förnya ditt avtal efter 4 år, kan du helt
                enkelt behålla maskinen som din. Betalningen fortsätter tills
                det har gått 60 månader från avtalsstart, då den är
                färdigbetald.
              </p>
            </Margins>
          </b-col>
          <b-col cols="6" xl="3">
            <EkoWorldSVG class="icon icon--extra-extra-large" />
          </b-col>
        </b-row>

        <Separator size="medium" hasLine />

        <h3>VILKA MODELLER KAN JAG VÄLJA MELLAN?</h3>

        <p>
          Du kan teckna ett STIHL All Inclusive avtal på alla våra iMOW®
          modeller. Detta för att du ska kunna få en robotgräsklippare som
          passar just dina behov - oavsett om din gräsmatta är 800 m² eller
          5.000 m². Din återförsäljare finns alltid där för att hjälpa dig med
          råd och vägledning, tillsammans hittar ni det perfekta alternativet.
          Du kan läsa mer om de olika iMOW® modellerna på
          <a
            href="https://www.imow.se"
            target="_blank"
            rel="noopener noreferrer"
            >www.imow.se</a
          >
        </p>

        <Separator size="medium" hasLine />

        <h3>IMOW® ÄR BÄST-I-TEST</h3>
        <p>
          Genom åren har iMOW® placerat sig högst upp på pallen i flertalet
          olika tester. 100% valde att rekommendera produkten när 35 testpiloter
          runtom i Norden fick testa och utvärdera iMOW® under en månads tid.
        </p>

        <Separator size="medium" hasLine />

        <h3>4 ENKLA STEG TILL EN IMPONERANDE GRÄSMATTA</h3>

        <List :items="listItems" variant="numbers" />

        <p class="margins__triple">
          <small class="text-extra-small"
            >*Fri installation inom en radie på 50 km från återförsäljarens
            butik. För sträckor över 50 km och installationsplatser utan
            landförbindelse tillkommer extra körkostnad.</small
          >
        </p>

        <Separator size="medium" hasLine />

        <b-embed
          v-if="hasTargetingConsentLevel"
          type="iframe"
          aspect="16by9"
          src="https://www.youtube.com/embed/mP2xR3IXpJo"
          allowfullscreen
        ></b-embed>

        <Separator size="medium" hasLine />

        <h3>{{ $t('RETURN_CHECKLIST') }}</h3>

        <List
          :items="returnChecklist"
          variant="numbers"
          class="margins__double"
        />

        <h5 class="margins__triple">{{ $t('RETURN_ITEMS') }}</h5>

        <List :items="returnItems" variant="bullets" />
      </Margins>
    </Container>

    <Separator size="medium" />

    <div id="map">
      <SelectStore view />
    </div>
  </div>
</template>

<script>
import { Container, Separator, Button, Margins, List } from '@/components';
import StihlInstallationSVG from '@/assets/images/stihl-installation.svg';
import StihlServiceSVG from '@/assets/images/stihl-service.svg';
import StihlWinterStorageSVG from '@/assets/images/stihl-winter-storage.svg';
import EkoWorldSVG from '@/assets/images/eko_world.svg';
import SelectStore from '@/views/SelectStore/SelectStore';
import { constants } from '@/mixins';
import { getDynamicTranslation } from '@/utils';
import { mapGetters } from 'vuex';
import { COOKIE_CONSENT_LEVELS } from '@/constants';

export default {
  name: 'MarketingView',
  mixins: [constants],
  components: {
    Container,
    Separator,
    Button,
    Margins,
    List,
    StihlInstallationSVG,
    StihlServiceSVG,
    StihlWinterStorageSVG,
    EkoWorldSVG,
    SelectStore,
  },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), 1);
    }
  },
  methods: {
    scrollTo(hashtag) {
      setTimeout(() => {
        window.location.href = hashtag;
      }, 1);
    },
  },
  computed: {
    ...mapGetters(['hasConsentByLevel']),
    listItems() {
      return [
        'Kontakta din närmaste<a href="https://stihlallinclusive.se/select-store"> STIHL återförsäljare</a> och välj den modell som passar dig bäst.',
        'Återförsäljaren går igenom och installerar din nya iMOW® i din trädgård.*',
        'Du betalar en fast månadskostnad. Service och vinterförvaring ingår i 4 år.',
        'Förnya ditt avtal efter 4 år och få en ny iMOW®. Eller behåll den du har som din.',
      ];
    },
    returnChecklist() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_CHECKLIST_ITEMS'),
      ).map((r) => r);
    },
    returnItems() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_ITEMS_ITEMS'),
      ).map((r) => r);
    },
    hasTargetingConsentLevel() {
      return this.hasConsentByLevel(COOKIE_CONSENT_LEVELS.TARGETING);
    },
  },
};
</script>
